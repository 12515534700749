<template>
  <div class="RecruitmentQuota">
    <div class="RecruitmentQuota__title">
      <div class="titleTop">
        {{ partTitle }} / <span>{{ title }}</span>
      </div>
      <div class="buttonLine">
        <el-button @click="goback">返回</el-button>
        <el-button
          @click="submit"
          type="primary"
          v-if="!statusDisable"
          size="mini"
          >保存</el-button
        >
      </div>
    </div>
    <div class="line"></div>
    <div class="RecruitmentQuota__tab" v-if="position !== 'hc'">
      <el-tabs v-model="tabCon">
        <el-tab-pane label="HC审批" name="HCInformation"></el-tab-pane>
        <el-tab-pane label="Offer审批" name="OfferProposal"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="RecruitmentQuota__content" id="RecruitmentQuota__content">
      <HCInformation
        ref="RecruitmentQuotaRef"
        :position="position"
        v-if="tabCon === 'HCInformation'"
      />
      <OfferProposal
        :position="position"
        ref="RecruitmentQuotaRef"
        v-if="tabCon === 'OfferProposal'"
      ></OfferProposal>
    </div>
  </div>
</template>

<script>
import HCInformation from '@/views/quotaManagement/components/HCInformation'
import OfferProposal from '@/views/quotaManagement/components/OfferProposal'
export default {
  name: 'RecruitmentQuota',
  components: {
    HCInformation,
    OfferProposal
  },
  created() {
    let position = this.$route.query.position
    if (position) {
      this.position = position
    }
    let title = this.$route.query.title
    if (title) {
      this.title = title
    }
    let status = this.$route.query.status
    if (status) {
      this.status = status
    }
    let target = this.$route.query.target
    if (target) {
      if (target === 'financial') {
        this.partTitle = '财务审批'
      }
    }
  },
  mounted() {
    // let content = document.getElementById('RecruitmentQuota__content')
    // if (content) {
    //   if (this.position === 'hc') {
    //     content.style.height = 'calc(100vh - 60px - 60px)'
    //   }
    // }
  },
  computed: {
    statusDisable() {
      return this.status === 'chat'
    }
  },
  data() {
    return {
      tabCon: 'HCInformation',
      position: 'quto',
      title: '招聘配额',
      partTitle: '配额管理'
    }
  },
  methods: {
    goback() {
      if (this.position === 'quto') {
        this.$router.push({
          path: '/employmentApplication/quotaManagement'
        })
      } else {
        this.$router.push({
          path: '/employmentApplication/financialApproval'
        })
      }
    },
    submit() {
      this.$refs.RecruitmentQuotaRef.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
.RecruitmentQuota {
  //height: calc(100vh - 60px - 40px);
  background: white;
  overflow: hidden;
  padding: 20px 20px 0 20px;
  position: relative;

  &__title {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    .titleTop {
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #868686;
      margin-bottom: 20px;
      span {
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .line {
    height: 1px;
    width: 100%;
    margin: 0;
    background: rgb(224, 227, 234);
    position: absolute;
    left: 0;
    top: 55px;
  }
  .buttonLine {
    position: absolute;
    top: 12px;
    right: 10px;
  }
  &__tab {
    margin-bottom: 10px;
  }
  &__content {
    //padding-bottom: 30px;
    //height: calc(100vh - 60px - 40px - 50px - 70px);
    //overflow-y: scroll;
    //overflow-x: hidden;
  }
}
</style>
